import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown, Button } from 'react-bootstrap';
import { AuthUserContext } from '../Session';
import SignOutButton from '../../pages/SignOut';
import * as ROUTES from '../../constants/routes';
import { ImInstagram, ImFacebook } from 'react-icons/im';
import useWindowDimensions from '../windowDimensionHook';
import GiftCardsPage from '../../pages/GiftCards';
import locations from '../../pages/Location/config';
import { useHistory, useLocation } from 'react-router-dom';
import './side-drawer.scss';
import BookNowButton from '../BookNowButton/BookNowButton.js';
import BookEventsButton from '../BookEventsButton/BookEventsButton';
import MealeoButton from '../MealeoButton';

const Navigation = () => {
  const { height, width } = useWindowDimensions();
  const [showGiftCards, setShowGiftCards] = useState(false);
  const [showCalendar, setShowCalendar] = useState(true);

  const NavigationAuth = ({ authUser }) => (
    <div className="side-drawer-container">
      <div className="side-drawer text-white">
        <div>
          <Link className="d-flex p-4" to={ROUTES.LANDING}>
            <img
              src="https://storage.googleapis.com/thebunker-assets/thebunker/The_Bunker_KO.png"
              width="100%"
              className="mb-5 pt-5"
            />
          </Link>
          <ul className="router-locations">
            <li className="mb-4">
              <SignOutButton />
            </li>
            <li className="mb-4">
              <Link className="text-white pb-3" to={ROUTES.ADMIN}>
                ADMIN PANEL
              </Link>
            </li>
						<li className="mb-4">
              <Link className="text-white pb-3" to={ROUTES.BEER_ADMIN}>
                EDIT BEER
              </Link>
            </li>
						<li className="mb-4">
              {/* <Link className="text-white pb-3" to={ROUTES.REVIEWS}> */}
              <Link className="text-white pb-3" to={'/reviews?locationId=thebunkercliftonpark&locationName=The%20Bunker%20Clifton%20Park&username=John%20Doe&date=Friday%20January%2019th%2C%202024'}>
                REVIEWS PREVIEW
              </Link>
            </li>
            {/* <li className="mb-4">
              <Link className="text-white pb-3" to={ROUTES.SIGNTV}>
                SIGN TV
              </Link>
            </li>
            <li className="mb-4">
              <Link className="text-white pb-3" to="/ad">
                CP SIGN TV
              </Link>
            </li> */}
            <li className="mb-4">
              <Link className="text-white pb-3" to="/calendar-admin">
                CALENDAR
              </Link>
            </li>
						 {/*
            <li className="mb-4">
              <Link className="text-white pb-3" to="/admh">
                MH SIGN TV
              </Link>
            </li> */}
            {/* <li className="mb-4">
              <Link className="text-white pb-3" to="/admhtest">
                TEST
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );

  const NavigationAuthMobile = () => {
    const history = useHistory();

    return (
      <Navbar
        className="side-drawer-mobile justify-content-between w-100"
        expand="lg"
        collapseOnSelect={true}
      >
        <Link
          className="d-flex justify-content-between"
          style={{ height: '50px' }}
          to={ROUTES.LANDING}
        >
          <img
            src="https://storage.googleapis.com/thebunker-assets/thebunker/thebunker_mobile_header.png"
            className="p-1"
            style={{ maxWidth: '170px' }}
          />
        </Link>
        <Navbar.Toggle
          className=""
          aria-controls="basic-navbar-nav"
        />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-inverse">
            {/* <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" /> */}
            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to={ROUTES.ADMIN}
            >
              ADMIN PANEL
            </Nav.Link>
						<Nav.Link
              as={Link}
              className="text-white"
              eventKey="2"
              to={ROUTES.BEER_ADMIN}
            >
              EDIT BEER
            </Nav.Link>
						{/* <Nav.Link as={Link} className="text-white" eventKey="3" to={ROUTES.REVIEWS}> */}
						<Nav.Link as={Link} className="text-white" eventKey="3" to={'/reviews?locationId=thebunkercliftonpark&locationName=The%20Bunker%20Clifton%20Park&username=John%20Doe&date=Friday%20January%2019th%2C%202024'}>
							Reviews Preview
						</Nav.Link>
            {/* <Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to={ROUTES.SIGNTV}
            >
              SIGN TV
            </Nav.Link>
            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to="/ad"
            >
              CP SIGN TV
            </Nav.Link> */}
						<Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to="/calendar-admin"
            >
              CALENDAR
            </Nav.Link>
						{/* 
            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to="/admh"
            >
              MH SIGN TV
            </Nav.Link> */}
            <div className="mt-4">
              <SignOutButton />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  const NavigationNonAuth = () => {
    return (
      <div className="side-drawer-container">
        <div className="side-drawer text-white">
          <div>
            <Link className="d-flex p-4" to={ROUTES.LANDING}>
              <img
                src="https://storage.googleapis.com/thebunker-assets/thebunker/The_Bunker_KO.png"
                width="100%"
                className="mb-5 pt-5"
              />
            </Link>
            <ul className="router-locations">
              <li className="m-2 w-75">
                <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" />
              </li>
							<li className="m-2 mb-3 w-75">
                <BookEventsButton />
              </li>
              <li className="mb-4">
                <Link
                  className="text-white pb-3"
                  to={ROUTES.LOCATION}
                >
                  LOCATIONS
                </Link>
              </li>
							<li className="mb-4">
                <Link
                  className="text-white pb-3"
                  to={ROUTES.LEAGUES}
                >
                  LEAGUES
                </Link>
              </li>
              <li className="mb-4">
                <Link className="text-white" to={ROUTES.ABOUT_US}>
                  ABOUT US
                </Link>
              </li>
              <li className="mb-4">
                <Link className="text-white" to={ROUTES.FAQS}>
                  FAQs
                </Link>
              </li>
              <li className="mb-4">
                <Link className="text-white" to={ROUTES.MENUS}>
                  MENUS
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  className="text-white pb-3"
                  to={ROUTES.LOCATION}
                >
                  ORDER ONLINE
                </Link>
              </li>
              <li className="mb-4">
                <Link className="text-white" to={ROUTES.CONTACT}>
                  CONTACT
                </Link>
              </li>
              <li className="mb-4">
                <a
                  className="text-white"
                  href="https://www.bunkerparties.com"
                >
                  EVENTS
                </a>
              </li>
              <li className="mb-4">
                <Link className="text-white" to={ROUTES.CAREERS}>
                  CAREERS
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="#"
                  className="text-white"
                  onClick={() => {
                    setShowGiftCards(true);
                  }}
                >
                  GIFT CARDS
                </Link>
              </li>
              <li className="w-100">
                {/* INSERT SOCIAL MEDIA HERE */}
                <div className="text-white d-flex justify-content-center mt-1 w-100">
                  <a
                    className="mx-2 text-white"
                    target="_blank"
                    href="https://www.instagram.com/getinthebunker/"
                  >
                    <ImInstagram />
                  </a>
                  <a
                    className="mx-2 text-white"
                    target="_blank"
                    href="https://www.facebook.com/getinthebunkerNY"
                  >
                    <ImFacebook />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const NavigationNonAuthMobile = () => {
    const history = useHistory();

    return (
      <Navbar
        className="side-drawer-mobile justify-content-between w-100"
        expand="lg"
        collapseOnSelect={true}
      >
        <Link
          className="d-flex justify-content-between"
          style={{ height: '50px' }}
          to={ROUTES.LANDING}
        >
          <img
            src="https://storage.googleapis.com/thebunker-assets/thebunker/thebunker_mobile_header.png"
            className="p-1"
            style={{ maxWidth: '170px' }}
          />
        </Link>
        <Navbar.Toggle
          className=""
          aria-controls="basic-navbar-nav"
        />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-inverse">
            <BookNowButton url="https://thebunker-teefindr-live.web.app/location/thebunker" />
            <BookEventsButton />
            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="1"
              to={ROUTES.LANDING}
            >
              HOME
            </Nav.Link>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="2"
              to={ROUTES.LOCATION}
            >
              LOCATIONS
            </Nav.Link>

						<Nav.Link
              as={Link}
              className="text-white"
              eventKey="2"
              to={ROUTES.LEAGUES}
            >
              LEAGUES
            </Nav.Link>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="3"
              to={ROUTES.ABOUT_US}
            >
              ABOUT US
            </Nav.Link>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="5"
              to={ROUTES.FAQS}
            >
              FAQs
            </Nav.Link>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="6"
              to={ROUTES.MENUS}
            >
              MENUS
            </Nav.Link>

            <li className="py-2">
              <Link className="text-white pb-3" to={ROUTES.LOCATION}>
                ORDER ONLINE
              </Link>
            </li>

            <li className="py-2">
              <a
                className="text-white"
                href="https://www.bunkerparties.com"
              >
                EVENTS
              </a>
            </li>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="8"
              to={ROUTES.CAREERS}
            >
              CAREERS
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="#"
              className="text-white"
              eventKey="9"
              onClick={() => {
                setShowGiftCards(true);
              }}
            >
              GIFT CARDS
            </Nav.Link>

            <Nav.Link
              as={Link}
              className="text-white"
              eventKey="10"
              to={ROUTES.CONTACT}
            >
              CONTACT
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser?.roles['ADMIN'] ? (
          ['/ad', '/admh'].includes(
            location.pathname,
          ) ? null : width > 992 ? (
            <div className="bg-black">
              <NavigationAuth />
            </div>
          ) : (
            <NavigationAuthMobile />
          )
        ) : (
          <div className="bg-black">
            <GiftCardsPage
              show={showGiftCards}
              hide={() => {
                setShowGiftCards(false);
              }}
            />

            {width > 992 ? (
              <NavigationNonAuth />
            ) : (
              <NavigationNonAuthMobile />
            )}
          </div>
        )
      }
    </AuthUserContext.Consumer>
  );
};

export default Navigation;
