import React, { useEffect, useState } from 'react';
import { withFirebase } from '../../components/Firebase';
import { format } from 'date-fns';
import Ticker from 'react-ticker';
import './EventTicker.scss'; // Adjust the import to your file structure

function EventTicker(props) {
  const [events, setEvents] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      const querySnapshot = await props.firebase.calendarEventsAll().get();
      let allEvents = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const filteredEvents = data.calendarEvents?.filter(event => event.showOnFeed);
        if (filteredEvents) {
          allEvents = allEvents.concat(filteredEvents);
        }
      });

      // Sort events by timestamp
      allEvents.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);

      setEvents(allEvents);
      setReady(true); // Trigger the ticker to initialize
    };

    fetchEvents();
  }, [props.firebase]);

  return (
    <>
      {events.length > 0 ? 
        <div className="event-ticker-container">
          {ready && (
            <Ticker speed={5} mode="smooth">
              {() => (
                <div className="ticker-content">
                  {events.map((event, index) => (
                    <div className="event" key={index}>
                      <div className="event-date">
                        {format(new Date(event.timestamp.seconds * 1000), 'EEEE, MMMM do')}
                      </div>
                      <div className="event-title text-primary">{event.title}</div>
                      <div className="event-description">{event.description}</div>
                      <div className="event-location">{event.locationName}</div>
                    </div>
                  ))}
                </div>
              )}
            </Ticker>
          )}
        </div> 
      : null}
    </>
  );
}

export default withFirebase(EventTicker);
