import React, { useState } from 'react';
import { Footer } from '../Landing';
import { withFirebase } from '../../components/Firebase';
import { useAlert } from 'react-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { sendEmail } from '../../utils/email';
import { formatPhone } from '../../utils/format';
import '../../utils/shared.scss';
import './index.scss';

const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const TEXT_REGEX = /[^a-zA-Z]/g;
const TEXTBOX_REGEX = /[^a-zA-Z0-9.,!$?\n ]/g;
const PHONE_REGEX = /[^\d]/g;

const HEADER = 'LESSONS';
const TITLE = 'Want to schedule a lesson at The Bunker?';
const SUBTITLE =
  'Contact us using the form below. We will coordinate the rest!';

const LessonsPage = (props) => {
  let [loading, setLoading] = useState(false);

  const alert = useAlert();

  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/events-bg.png"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const Form = () => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const onSubmit = (data) => console.log(data);

    return (
      <div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-input">
            <label>First Name</label>
            <input
              {...register('firstName', {
                required: true,
                maxLength: 20,
              })}
            />
            {errors.firstName?.type === 'required' && (
              <p>First name is required</p>
            )}
          </div>

          <div className="form-input">
            <label>Last Name</label>
            <input
              {...register('lastName', {
                required: true,
                pattern: /^[A-Za-z]+$/i,
              })}
            />
            {errors.lastName && <p>Last name is required</p>}
          </div>

          <div className="form-input">
            <label>Phone Number</label>
            <input
              type="phone"
              {...register('phone', { required: true })}
            />
            {errors.phone && <p>Phone number is required</p>}
          </div>

          <div className="form-input">
            <label>Email Address</label>
            <input
              type="email"
              {...register('email', { required: true })}
            />
            {errors.email && <p>Email address is required</p>}
          </div>

          <div className="form-input grid-span-2">
            <label>Message</label>
            <textarea
              type="textarea"
              {...register('message', { required: false })}
            />
            {/* {errors.message && <p>Email address is required</p>} */}
          </div>

          <input className="submit grid-span-2" type="submit" />
        </form>
      </div>
    );
  };

  const Main = () => {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <div className="main px-3 flex-fill h-100">
        <div className="pt-4">
          <h1 className="text-dark mb-4">{TITLE}</h1>
          <p className="ml-1">{SUBTITLE}</p>
          <hr />
        </div>
        {loading ? (
          <ClipLoader loading={loading} css={override} size={150} />
        ) : (
          <Form />
        )}
      </div>
    );
  };

  return (
    <div className="lessons-container">
      {Title}
      <Main />
      <Footer />
    </div>
  );
};

export default withFirebase(LessonsPage);
